import React from 'react';
import { graphql } from 'gatsby';

import Page from 'components/content/main';

const Pages = ({ data: { pages: { edges: [{ node: content }] } } }) => <Page content={content} />

export default Pages;

export const query = graphql`
    query MainPageQuery ($path: String!) {
        ...MarkdownPages
    }
`