import React, { useCallback, useEffect, useState } from 'react';

// import background from 'assets/images/banner-img.png';
import pubsub from 'components/lib/pubsub';
import { getUser } from 'components/lib/session';
import useIsMounted from 'components/hooks/use-is-mounted';

// const styles = () => ({
//     backgroundImage: `url(${background})`
// });

const HomeBanner = () => {
  const isMounted = useIsMounted();
  const [user, setUser] = useState(getUser());

  const unsetUser = useCallback(() => {
    if(isMounted.current) {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    pubsub.subscribe('user:unauthorised', unsetUser);
    return () => pubsub.unsubscribe('user:unauthorised', unsetUser);
  }, []);

  return <section></section>;
}

export default HomeBanner;