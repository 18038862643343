import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import {useTranslation } from 'react-i18next'
import config from 'components/config';
import Layout from 'components/layout';
import Banner from 'components/banner/home';
import greaterThanIcon from '../../../../content/media/greaterThanIcon.svg'
import metricsEn from '../../../../content/media/metrics_en.svg'
import metricsPt from '../../../../content/media/metrics_pt.svg'
import infoContentEn from '../../../../content/media/infoContent_en.svg'
import infoContentPt from '../../../../content/media/infoContent_pt.svg'
import rightArrow from '../../../../content/media/rightArrow.svg'
import customerPortalEn from '../../../../content/media/customerPortal_en.svg'
import customerPortalPt from '../../../../content/media/customerPortal_pt.svg'
import homeUser from '../../../../content/media/homeUser.svg'
import i18n from '../../../localization';


const {
  mainpageHeader,
  referenceSignupHeader,
  referenceSignupContent
} = config;



const updateContentBlock = (id, content) => {
  if (content) {
    const element = document
      .getElementById(id);

    if (element) {
      element.innerHTML = content;
    }
  }
};

const updateReferenceLink = () => {
  const coreBankLink = document
    .querySelector('.core-bank-link');

  if (coreBankLink) {
    coreBankLink.setAttribute('href',
      config.corebankWebsite
    );
  }
};

const MainPage = (props) => {
   const { content } = props
   const title = mainpageHeader || content.frontmatter.title
     const { t } = useTranslation()
     const rest = useTranslation()
     

   const meta = [
     {
       name: t('staticPage.home.description'),
       content: mainpageHeader || 'Portal'
     },
     { name: t('staticPage.home.keywords'), content: 'api, portal' }
   ]


   useEffect(() => {
 updateContentBlock(
      'reference-signup-header',
      referenceSignupHeader
    );

    if (referenceSignupContent) {
      updateContentBlock(
        'reference-signup-content',
        referenceSignupContent
      );
    } else {
      updateReferenceLink();
    }
   }, [])

 return (
   <Layout>
     <Helmet meta={meta} title={title} />

     <Banner {...{ ...content.frontmatter, ...{ title } }} />
     {/* <div id="main" dangerouslySetInnerHTML={{ __html: renderText(content.html) }} /> */}
     <div id="main">
       <section className="home-page-wrapper">
         <div className="title-blue-wrapper">
           <div className="title-card-wrapper">
             <div className="title-card-content">
               <div className="subtitle">
                 <div className="line"></div>
                 {t('staticPage.home.availableInBrazil')}
               </div>
               <h2>{t('staticPage.home.title')}</h2>
               <p>{t('staticPage.home.pageDescription')}</p>
               <div className="title-card-btn-wrapper">
                 <button className="success-btn">
                    <a href="/register">
                     <p>{t('staticPage.home.getStarted')}</p>
                     <figure>
                        <img
                          src={greaterThanIcon}
                          alt="greater than arrow"
                          loading="lazy"
                        />
                      </figure>
                    </a>
                 </button>
                 <button className="normal-btn">
                   <a href="/doc/user-guide">
                     {' '}
                     {t('staticPage.home.viewDocs')}
                   </a>
                 </button>
               </div>
             </div>
             <div>
               <figure className="metrics-image-wrapper">
                 <img src={i18n.language === 'pt' ? metricsPt : metricsEn} alt="metric" loading="lazy" />
               </figure>
             </div>
           </div>
         </div>
         <div className="home-page-width-wrapper">
           <div className="obaf-info-wrapper">
             <div className="our-info-content-title">
               <div className="content-what-includes">
                 <p>{t('staticPage.home.weOffer')}</p>
                 <h3>{t('staticPage.home.obafIncludes')}</h3>
               </div>
             </div>
             <div className="our-info-content">
               <div>
                 <figure className="info-content-image-wrapper">
                   <img src={i18n.language === 'pt' ? infoContentPt : infoContentEn} alt="accounts" loading="lazy" />
                 </figure>
               </div>
               <div className="our-info-content-secondary">
                 <h4>{t('staticPage.home.fintechPortal')}</h4>
                 <div className="info-content-secondary-wrapper">
                   <p className="info-secondary-heading">
                     {t('staticPage.home.accountInformation')}
                   </p>
                   <p>{t('staticPage.home.accountDescription')}</p>
                 </div>
                 <div className="info-content-secondary-wrapper">
                   <p className="info-secondary-heading">
                     {t('staticPage.home.monitoring')}
                   </p>
                   <p>{t('staticPage.home.operationStaff')}</p>
                 </div>
                 <div className="info-content-secondary-wrapper">
                   <p className="info-secondary-heading">
                     {t('staticPage.home.marketPlace')}
                   </p>
                   <p>{t('staticPage.home.marketPlaceDescription')}</p>
                 </div>
                 <a href="/register">
                   <p>{t('staticPage.home.getStarted')}</p>
                   <figure>
                     <img src={rightArrow} alt="right arrow" loading="lazy" />
                   </figure>
                 </a>
               </div>
             </div>
           </div>
           <div className="our-info-content customer-portal">
             <div className="our-info-content-secondary">
               <h4>{t('staticPage.home.customerPortal')}</h4>
               <div>
                 <p>{t('staticPage.home.customerPortalDescription')}</p>
                 <a href="/register">
                   <p>{t('staticPage.home.getStarted')} </p>
                   <figure>
                     <img src={rightArrow} alt="right arrow" loading="lazy" />
                   </figure>
                 </a>
               </div>
             </div>
             <div>
               <figure className="info-content-image-wrapper">
                 <img src={i18n.language === 'pt' ? customerPortalPt : customerPortalEn} alt="customer" loading="lazy" />
               </figure>
             </div>
           </div>
           <div className="our-info-content user-section">
             <div className="info-content-user-image-div-wrapper">
               <figure className="info-content-user-image-wrapper">
                 <img src={homeUser} alt="user" loading="lazy" />
               </figure>
             </div>
             <div className="our-info-content-secondary">
               <a>
                 <p>{t('staticPage.home.coreFeatures')}</p>
                 <figure>
                   <img src={rightArrow} alt="right arrow" loading="lazy" />
                 </figure>
               </a>
               <h3>{t('staticPage.home.whyBanfico')}</h3>
               <div className="info-content-questions">
                 <p>✔ {t('staticPage.home.point1')}​</p>
                 <p>✔ {t('staticPage.home.point2')}</p>
                 <p>✔ {t('staticPage.home.point3')}</p>
                 <p>✔ {t('staticPage.home.point4')}</p>
                 <p>✔ {t('staticPage.home.point5')}</p>
                 <p>✔ {t('staticPage.home.point6')}</p>
                 <p>✔ {t('staticPage.home.point7')}​​</p>
                 <p>✔ {t('staticPage.home.point8')}​</p>
               </div>
               <a href="/register">
                 <button className="success-btn">
                   {t('staticPage.home.startForFree')}
                 </button>
               </a>
             </div>
           </div>
         </div>
       </section>
     </div>
   </Layout>
 )
}

export default MainPage